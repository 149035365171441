/* colours */
/*orange*/
/*blue-grey*/
/*navy*/
/*offwhite*/
/*light grey*/
/*medium grey*/
/* fonts */
/* shared */
/* helper functions and reusable blocks */
body {
  font-family: yantramanav, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #58595B;
  line-height: 1.2;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
  max-width: 100%;
}

img {
  height: auto !important;
}

hr {
  width: 80%;
  border-color: #cf5c34;
  margin: 4em auto;
}

ul, ol {
  /* align with left */
  padding-left: 20px;
}
ul li, ol li {
  padding-left: 10px;
  margin: 10px 0px;
}

h1, h2, h3, h4 {
  font-family: lato, sans-serif;
  color: #cf5c34;
  margin-bottom: 0px;
}

.clear-float {
  clear: both;
}

.highlight {
  color: #cf5c34 !important;
}

span.secondary {
  color: #0C334A !important;
}

a {
  color: #cf5c34;
}

a.button,
.button {
  /* hovers + actives included */
  font-family: lato, sans-serif;
  font-size: 25px;
  display: inline-block;
  vertical-align: middle;
  color: #0C334A;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  padding: 10px 20px;
  min-width: 200px;
  max-width: 100%;
  color: #0C334A;
  background-color: transparent;
  border: 1px solid #0C334A;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
a.button:hover,
.button:hover {
  border-color: #b6c2c9;
  background-color: #b6c2c9;
}
a.button:active, a.button.active,
.button:active,
.button.active {
  color: #eff3f4;
  border-color: #0C334A;
  background-color: #0C334A;
}

img.display-left {
  float: left;
  max-width: 50%;
  margin-right: 20px;
  margin-bottom: 10px;
}

img.display-right {
  float: right;
  max-width: 50%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.www-icon,
.pdf-icon {
  display: block;
  width: 80px;
  height: 95px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 10px 2px;
}

.www-icon {
  background-position: bottom;
  background-image: url("../assets/web-resource.svg");
}

.pdf-icon {
  background-image: url("../assets/pdf-resource.svg");
}

.col-wrap {
  display: flex;
  justify-content: space-evenly;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.two-col {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  box-sizing: border-box;
}

@media only screen and (max-width: 960px) {
  .col-wrap {
    display: block;
  }

  .two-col {
    width: 100%;
  }
}
@media only screen and (max-width: 760px) {
  img.display-left,
img.display-right {
    float: none;
    max-width: 100%;
    margin: 20px 0;
  }
}
@media only screen and (max-width: 300px) {
  .button {
    width: 100%;
    min-width: 0px;
  }
}