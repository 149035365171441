@import 'vars';

body {
    font-family: $text;
    font-weight: 400;
    font-size: 18px;
    color: $mgrey;
    line-height: 1.2;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
hr {
    width: 80%;
    border-color: $primary;
    margin: 4em auto;
}
ul, ol {
    /* align with left */
    padding-left: 20px;
    li {
        padding-left: 10px;
        margin: 10px 0px;
    }
}

h1, h2, h3, h4 {
    font-family: $heading;
    color: $primary;
    margin-bottom: 0px;
}

.clear-float {
    clear:both;
}

.highlight {
    color: $primary !important;
}
span.secondary {
    color: $secondary !important;
}

a {
   color: $primary;
}
a.button,
.button {
    /* hovers + actives included */
    @include button;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

.www-icon,
.pdf-icon {
    display: block;
    width: 80px;
    height: 95px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 10px 2px;
}
.www-icon {
    background-position: bottom;
    background-image: url("../assets/web-resource.svg");
}
.pdf-icon {
    background-image: url("../assets/pdf-resource.svg");
}

.col-wrap {
    display: flex;
    justify-content: space-evenly;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
}
.two-col {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    box-sizing: border-box;
}

@media only screen and (max-width: 960px){
    .col-wrap {
        display: block;
    }
    .two-col {
        width: 100%;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}

@media only screen and (max-width: 300px){
    .button {
        width: 100%;
        min-width: 0px;
    }
}